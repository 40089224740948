<template>
	<div>
		<a-modal @cancel="close" centered :header="null" :footer="null" :visible="modal.visible">
			<h5>{{ modal.edit ? "Edit" : "Add New" }} Seller's Agent</h5>
			<a-form-model ref="modal" :model="modal" class="mt-4">
				<a-form-model-item prop="name" label="Agent Name" required :rules="req('Please enter the Agent Name')">
					<a-input v-model="modal.name" placeholder="Enter the Agent Name" size="large"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="email" label="Agent Email" required :rules="req('Please enter the Agent Email')">
					<a-input v-model="modal.email" placeholder="Enter the Agent Email" size="large"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="company" label="Brokerage/Company Name" required
					:rules="req('Please enter the Brokerage/Company Name')">
					<a-input v-model="modal.company" placeholder="Enter the Brokerage/Company Name" size="large" :disabled="!!sellersAgents.length"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="recoRegistrationNumber" label="Reco Registration Number" required
					:rules="req('Please enter the Reco Registration Number')">
					<a-input v-model="modal.recoRegistrationNumber" placeholder="Enter the Reco Registration Number"
						size="large"></a-input>
				</a-form-model-item>
			</a-form-model>
			<div class="dF mt-3" style="justify-content: flex-end">
				<a-button @click="close" size="large" class="mr-3">CANCEL</a-button>
				<a-button @click="submit" type="primary" size="large">{{ modal.edit ? "UPDATE" : "CREATE" }}</a-button>
			</div>
		</a-modal>
		<a-table class="white-table" :columns="columns" :data-source="sellersAgents" :rowKey="(e) => e.id">
			<div slot="action" slot-scope="obj">
				<a-icon type="edit" class="cursor-pointer hover:text-primary" @click="editAgent(obj)" />
				<i class="fa fa-trash ml-3 cursor-pointer hover:text-primary" @click="deleteAgent(obj)" />
			</div>
		</a-table>
		<div class="dF aC" style="gap: 20px">
			<div @click="createNew" class="dF aC p-2" style="color: var(--orange); cursor: pointer; gap: 20px">
				<a-icon style="font-size: 25px" type="plus-circle" />
				<span>Add Seller's Agent</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			modal: {
				visible: false,
				edit: false,
				id: Date.now() + '',
				company: '',
				name: '',
				email: '',
				recoRegistrationNumber: '',
			},
		};
	},
	computed: {
		appSettings() {
			let app = this.$store.state.appData.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},

		sellersAgents() {
			return this.appSettings.sellersAgents || [];
		},

		columns() {
			let list = [
				{
					title: "Agent Name",
					key: "name",
					dataIndex: "name",
				},
				{
					title: "Agent Email",
					key: "email",
					dataIndex: "email",
				},
				{
					title: "Brokerage/Company Name",
					key: "company",
					dataIndex: "company",
				},
				{
					title: "Reco Registration Number",
					key: "recoRegistrationNumber",
					dataIndex: "recoRegistrationNumber",
				},
				{
					title: "Action",
					key: "action",
					scopedSlots: { customRender: "action" },
				},
			]

			if (this.$p < 40) {
				list.splice(4)
			}

			return list
		}
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),

		close() {
			this.$refs.modal && this.$refs.modal.resetFields();
			this.modal.visible = false;
			this.modal.edit = false;
		},

		createNew() {
			this.modal = {
				edit: false,
				visible: true,
				company: '',
				name: '',
				email: '',
				recoRegistrationNumber: '',
				id: Date.now() + "",
			};
			if (this.sellersAgents.length) {
				this.modal.company = this.sellersAgents[0].company
			}
		},

		editAgent(agent) {
			let agentDetails = JSON.parse(JSON.stringify(agent));
			this.modal = {
				...this.modal,
				edit: true,
				visible: true,
				...agentDetails,
			};
		},
		submit() {
			this.$refs.modal && this.$refs.modal.validate((valid) => {
				if (valid) {
					let { visible, edit, ...agent } = this.modal;
					agent = JSON.parse(JSON.stringify(agent));

					if (this.modal.edit) {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "sellersAgents",
							action: "arrayEdit",
							what: agent,
						});
					} else {
						agent.id = Date.now() + "";
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "sellersAgents",
							action: "arrayAdd",
							what: agent,
						});
					}
					this.close();
				} else {
					return false;
				}
			});
		},

		deleteAgent(e) {
			this.$confirm({
				title: "Delete Seller's Agent",
				content: (h) => (
					<div>Do you want to delete this seller's agent ?</div>
				),
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: () => {
					let updatedList = this.sellersAgents.filter((x) => x.id !== e.id);
					this.$store.commit("SET_OPTIONS", {
						where: "sellersAgents",
						what: updatedList,
					});
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		},
	},
};
</script>

<style>
</style>
