<template>
	<div>
		<a-row :gutter="16">
			<a-col :span="10">
				<h4>Deposit Structures</h4>
				<a-table class="white-table" :columns="structureColumns" :data-source="data" :rowKey="(e) => e.id">
					<div slot="name" slot-scope="obj" @click="onDepositStructureSelect(obj)" class="cursor-pointer"
						:style="`color: ${selectedDepositStructure && selectedDepositStructure.name === obj.name ? 'var(--primary)' : ''}`">
						{{ obj.name }}
					</div>
					<div slot="deposits" slot-scope="obj" @click="onDepositStructureSelect(obj)" class="cursor-pointer"
						:style="`color: ${selectedDepositStructure && selectedDepositStructure.name === obj.name ? 'var(--primary)' : ''}`">
						{{ obj.items.length }}
					</div>
					<div slot="action" slot-scope="obj">
						<a-icon type="edit" class="cursor-pointer hover:text-primary"
							@click="$emit('editStructure', obj)" />
						<i class="fa fa-trash ml-3 cursor-pointer hover:text-primary"
							@click="deleteDepositStructure(obj)" />
					</div>
				</a-table>
			</a-col>
			<a-col :span="2"></a-col>
			<a-col :span="12" v-if="selectedDepositStructure">
				<h4>Deposits of <strong>{{ selectedDepositStructure.name }}</strong> Structure</h4>
				<a-table class="white-table" :columns="columns" :data-source="selectedDepositStructure.items"
					:rowKey="(e) => e.order">
					<span slot="order" slot-scope="obj">
						{{ obj.order + 1 }}
					</span>
					<span slot="date" slot-scope="obj"> {{ obj.date.value }} Days </span>
					<span slot="amount" slot-scope="obj">
						<span v-if="!obj.amount.perc">${{ formatNumber(obj.amount.value) }}</span>
						<span v-else>{{ obj.amount.value }}%</span>
					</span>
					<div slot="action" slot-scope="obj">
						<a-icon type="edit" class="cursor-pointer hover:text-primary" @click="$emit('edit', obj)" />
						<i class="fa fa-trash ml-3 cursor-pointer hover:text-primary" @click="deleteDeposit(obj)" />
					</div>
				</a-table>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { formatNumber } from "bh-mod";
export default {
	props: {
		data: {
			default: () => [],
			type: Array,
		},
		depositStructure: {
			default: () => ({}),
			type: Object,
		}
	},
	data() {
		return {
			selectedDepositStructure: null,
		};
	},
	computed: {
		structureColumns() {
			let list = [
				{
					title: "Name",
					key: "name",
					scopedSlots: { customRender: "name" },
				},
				{
					title: "No. Deposits",
					key: "deposits",
					scopedSlots: { customRender: "deposits" },
				},
				{
					title: "Action",
					key: "action",
					scopedSlots: { customRender: "action" },
				}
			]

			if (this.$p < 40) {
				list.splice(2)
			}

			return list
		},

		columns() {
			let list = [
				{
					title: "Order",
					key: "order",
					scopedSlots: { customRender: "order" },
				},
				{
					title: "Deposit Date",
					key: "date",
					scopedSlots: { customRender: "date" },
				},
				{
					title: "Deposit Amount",
					key: "depositAmount",
					scopedSlots: { customRender: "amount" },
				},
				{
					title: "Action",
					key: "action",
					scopedSlots: { customRender: "action" },
				},
			]

			if (this.$p < 40) {
				list.splice(3)
			}

			return list
		}
    },
	watch: {
		depositStructure(val) {
			this.selectedDepositStructure = val;
		}
	},
	created() {
		if (this.data.length) {
			this.onDepositStructureSelect(this.data[0])
		}
	},
	methods: {
		formatNumber,

		onDepositStructureSelect(item) {
			this.selectedDepositStructure = item;
			this.$emit('onSelect', item);
		},

		deleteDepositStructure(e) {
			this.$confirm({
				title: `Delete Deposit Structure`,
				content: (h) => (
					<div>Do you want to delete Deposit Structure?</div>
				),
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: () => {
					let newDeposits = this.data
						.filter((x) => x.id !== e.id);
					this.$store.commit("SET_OPTIONS", {
						where: "deposits",
						what: newDeposits,
					});
					if (this.selectedDepositStructure.id === e.id && newDeposits.length) {
						this.onDepositStructureSelect(newDeposits[0])
					}
					if (!newDeposits.length) {
						this.selectedDepositStructure = null;
					}
				},
			});
		},
		deleteDeposit(e) {
			this.$confirm({
				title: `Delete Deposit`,
				content: (h) => (
					<div>Do you want to delete Deposit #{e.order + 1}?</div>
				),
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: () => {
					this.selectedDepositStructure.items = this.selectedDepositStructure.items
						.filter((x) => x.order !== e.order)
						.map((x, xI) => ({ ...x, order: xI }));

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "deposits",
						action: "arrayEdit",
						what: { ...this.selectedDepositStructure },
						key: "id",
					});
				},
			});
		},
	},
};
</script>

<style>
</style>
