<template>
	<a-table :paginate="false" class="white-table" :columns="columns" :data-source="data" :rowKey="(r) => r.email">
		<div slot="signingParty" slot-scope="obj">
			<span v-if="obj.index === 0">Main Signing Party</span>
			<span v-else>Additional Signing Party #{{ obj.index }}</span>
		</div>
		<div slot="action" slot-scope="obj">
			<a-icon type="edit" class="cursor-pointer hover:text-primary" @click="$emit('edit', obj)" />
			<i class="fa fa-trash ml-3 cursor-pointer hover:text-primary" @click="deleteThis(obj)" />
		</div>
	</a-table>
</template>

<script>

export default {
	props: {
		data: {
			default: () => [],
			type: Array,
		},
	},
	data() {
		return {

		}
	},
	computed: {
		columns() {
			let list = [
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Signing Party',
					key: 'Signing Party',
					scopedSlots: { customRender: 'signingParty' },
				},
				{
					title: 'Role',
					dataIndex: 'role',
					key: 'role',
				},
				{
					title: 'Email Address',
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: 'Company',
					dataIndex: 'company',
					key: 'company',
				},
				{
					title: 'Action',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			]

			if (this.$p < 40) {
				list.splice(4)
			}

			return list
		}
	},
	methods: {
		deleteThis(e) {
			this.$confirm({
				title: "Delete Participant",
				content: h => <div>Do you want to delete <b>{e.name}</b>?</div>,
				okText: 'DELETE',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				onOk: () => {
					let index = this.data.findIndex(x => x.email === e.email)
					this.$store.commit('SET_PROP', { where: ['allSettings', 'app', 'options', 'participants', index], del: true })
				}
			})
		}
	}

}
</script>

<style></style>
